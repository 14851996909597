<template>
  <div class="back_drop" v-if="isShowSuccessModal">
    <div class="card">
      <div class="card_header d_flex justify_content_center">
        <div class="card_header_content">
          <div class="icon">
            <img src="../../../assets/images/public/ic_payment_tick01.png" alt="" />
          </div>
          <div class="text_primary font_weight_bold">支付成功</div>
        </div>
      </div>
      <div class="card_body">
        <div class="d_flex justify_content_center payment">
          <div class="payment_title">{{ amount }}</div>
        </div>
        <div class="item">
          <div class="d_flex item_list border_top">
            <div class="text_light">付款信息</div>
            <div>余额</div>
          </div>
          <div class="d_flex item_list border_top">
            <div class="text_light">积分奖励</div>
            <div>
              获得
              <span class="text_primary">{{ amount }}</span>
              积分，现有
              <span class="text_primary">{{ integral }}</span>
              积分
            </div>
          </div>
        </div>
      </div>
      <div class="btn_default btn_primary" @click="closeHandler">
        完成
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessModal",
  props: {
    isShowSuccessModal: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    integral: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    closeHandler() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.back_drop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_between {
  justify-content: space-between;
}

.card_header_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.card {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &_header {
    padding: 3.2rem 0.75rem 0 0.075rem;
    position: relative;
  }
}

.border_top {
  border-top: 0.75px solid #d4d4d481;
}

.payment {
  font-size: 0.8rem;
  align-items: baseline;
  padding: 1rem 1.2rem 2.5rem 1.2rem;
  text-align: center;

  &_title {
    font-size: 2.8rem;
  }
}

.item {
  font-size: 0.8rem;
}

.item_list {
  padding: 0.75rem 1.2rem;
}

.btn_default {
  display: inline-block;
  align-self: center;
  text-align: center;
  vertical-align: middle;
  background-color: var(--light-gray);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 1.25rem;
  width: 90%;
  margin: 2rem 0 3rem 0;
}

.text_light {
  color: var(--gray);
  margin-right: 0.75rem;
}

.text_primary {
  color: var(--orange);
  text-decoration: none;
}

.font_weight_bold {
  font-weight: bold;
}

.btn_primary {
  color: #fff;
  background-color: var(--orange);
  max-width: 371px;
  margin: 0 auto;
}

.icon {
  display: block;
  width: 40%;
  padding: 0.1rem;
  margin: 0.75rem 0;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
</style>
