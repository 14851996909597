<template>
  <div class="back_drop" v-if="isShow">
    <!-- <div class="card" :class="{ mt_6rem: hasMarginTop }"> -->
    <div class="card">
      <div class="card_header d_flex justify_content_center border_bottom">
        <div class="close" @click="closeHandler">
          <div class="close_icon"></div>
        </div>
        <div class="align_self_center">{{ cardTitle }}</div>
      </div>
      <div class="card_body mt_1rem" v-if="!isShowData">
        <LoadingSpinner :isLoading="isLoading" class="position_absolute" />
        <div>{{ responseMsg }}</div>
      </div>
      <template v-else>
        <div class="card_body" :class="{ opacity: !isShowData }" v-if="step === 0">
          <div class="d_flex justify_content_center payment">
            <div>¥</div>
            <div class="payment_title" v-if="orderAmount">
              {{ orderAmount }}
            </div>
          </div>
          <div class="item">
            <div class="d_flex justify_content_between item_list border_bottom">
              <div class="text_light">订单信息</div>
              <div v-if="merchantName">{{ merchantName }}</div>
            </div>
            <div class="d_flex justify_content_between item_list border_bottom">
              <div class="text_light">订单编号</div>
              <div v-if="orderNo">{{ orderNo }}</div>
            </div>
            <div class="d_flex justify_content_between item_list border_bottom">
              <div class="text_light">账户信息</div>
              <div v-if="userMobile">{{ userMobile }}</div>
            </div>
            <div class="d_flex justify_content_between item_list">
              <div class="text_light">付款方式</div>
              <div>余额支付</div>
            </div>
          </div>
        </div>
        <div class="card_body" v-else>
          <div class="input-captcha">
            <div class="input-box">
              <div class="input-content">
                <div class="box__img"><img src="@/assets/images/public/image@3x.png" alt="" /></div>
                <input type="password" ref="input0" v-model="input0" maxlength="6" />
                <!-- <input v-model.trim.number="input0" ref="input0" @keydown.8="deleteValue('input0', 'input0')"
                  @keyup="changeValue($event, 'input0', 'input1')" type="password" />
                <input v-model.trim.number="input1" ref="input1" @keydown.8="deleteValue('input1', 'input0')"
                  @keyup="changeValue($event, 'input1', 'input2')" type="password" />
                <input v-model.trim.number="input2" ref="input2" @keydown.8="deleteValue('input2', 'input1')"
                  @keyup="changeValue($event, 'input2', 'input3')" type="password" />
                <input v-model.trim.number="input3" ref="input3" @keydown.8="deleteValue('input3', 'input2')"
                  @keyup="changeValue($event, 'input3', 'input4')" type="password" />
                <input v-model.trim.number="input4" ref="input4" @keydown.8="deleteValue('input4', 'input3')"
                  @keyup="changeValue($event, 'input4', 'input5')" type="password" />
                <input v-model.trim.number="input5" ref="input5" @keydown.8="deleteValue('input5', 'input4')"
                  @keyup="changeValue($event, 'input5', 'input5')" type="password" /> -->
              </div>
            </div>
          </div>
          <div class="text_primary" @click="openResetModal">
            找回密码并支付
          </div>
        </div>
      </template>
      <button
        class="btn_default"
        @click="stepHandler"
        :class="{
          opacity: step !== 0,
          btn_disabled: !isShowData,
          btn_primary: isShowData,
        }"
        :disabled="!isShowData"
      >
        确认付款
      </button>
    </div>
    <div class="bindMobilePopup" v-if="isMobilePopup == true">
      <div class="zone">
        <div class="title">您还未設定安全密碼，是否去设置?</div>
        <div class="box__check">
          <div @click="isMobilePopup = false">取消</div>
          <div @click="$router.push('/my/paymentMethod/security')">去设置</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "PaymentModal",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isShowData: {
      type: Boolean,
      default: false,
    },
    orderAmount: {
      type: Number,
      default: 0,
    },
    merchantName: {
      type: String,
      default: "",
    },
    orderNo: {
      type: String,
      default: "",
    },
    userMobile: {
      type: String,
      default: "",
    },
    hasMarginTop: {
      type: Boolean,
      default: false,
    },
    responseMsg: {
      type: String,
      default: "",
    },
    isSecurityCode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      step: 0,
      input0: "",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      isMobilePopup: false,
    };
  },
  watch: {
    step(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetInputs();
      }
    },
    isShow(newValue) {
      if (newValue) {
        this.step = 0;
        this.resetInputs();
      }
    },
    input0(val) {
      if (val.length == 6) {
        this.$refs["input0"].selectionEnd = 5;
        this.$refs["input0"].blur();
        this.$emit("updatePassword", this.input0);
      }
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    cardTitle() {
      return this.step === 0 ? "付款详情" : "请输入安全密码";
    },
    // securityCode() {
    //   return (
    //     this.input0 +
    //     this.input1 +
    //     this.input2 +
    //     this.input3 +
    //     this.input4 +
    //     this.input5
    //   );
    // },
  },
  methods: {
    resetInputs() {
      this.input0 = "";
    },
    closeHandler() {
      if (this.step === 1) {
        this.step = 0;
      } else {
        this.$emit("close");
      }
    },
    stepHandler() {
      if (this.isSecurityCode == false) {
        this.isMobilePopup = true;
      }
      if (this.step === 1) return;
      this.step++;
    },
    // deleteValue(inputValue, previousItem) {
    //   // 键盘按下时$event，当前input，上一个input
    //   if (this[inputValue].length > 0) {
    //     // 当前有值，清空之
    //     this[inputValue] = "";
    //   } else {
    //     // 当前没有值，光标跳转到上一个input，并清空该input值
    //     this.$nextTick(() => {
    //       this.$refs[previousItem].focus();
    //       this[previousItem] = "";
    //       this[inputValue] = "";
    //     });
    //   }
    // },
    changeValue(e, inputValue, nextItem) {
      // 键盘抬起时$event，当前input，下一个input
      if (e.keyCode !== 8) {
        this.$nextTick(() => {
          this.$refs[nextItem].focus(); // 截取当前值最后一位，跳到下一个input
          this[inputValue] = this[inputValue].toString().slice(-1);
          if (this.securityCode.length !== 6) return;
          this.$emit("updatePassword", this.input0);
        });
      }
    },
    openResetModal() {
      this.$emit("openResetModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.back_drop {
  background-color: rgba(0, 0, 0, 0.466);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_between {
  justify-content: space-between;
}

.align_self_center {
  align-self: center;
  text-align: center;
}

.mt_6rem {
  // margin-top: 6rem;
}

.card {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 10px 10px 0 0;
  // padding-bottom: 2rem;
  // overflow-y: scroll;
  // overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  &_header {
    padding: 1rem 0.75rem;
    position: relative;
  }
}

.card_body {
  overflow-y: scroll;
  height: 62vh;
}

.close {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 4rem;
  height: 3rem;
  left: 0rem;
  padding-left: 1rem;

  .close_icon {
    width: 1.4rem;
    height: 1.4rem;
    border: 2px solid var(--gray);
    display: block;
    border-radius: 50%;
    opacity: 0.35;

    &:before,
    &:after {
      position: absolute;
      top: 1.1rem;
      left: 1.65rem;
      content: " ";
      height: 0.8rem;
      width: 2px;
      background-color: var(--gray);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.payment {
  font-size: 0.8rem;
  align-items: baseline;
  padding-top: 0.5rem;
  // padding: 2rem 1.2rem 2.5rem 1.2rem;

  &_title {
    font-size: 2.8rem;
    margin-left: 0.25rem;
  }
}

.item {
  font-size: 0.8rem;
}

.item_list {
  padding: 0.7rem 1.2rem;
}

.text_light {
  color: var(--gray);
}

.btn_default {
  font-size: 1rem;
  display: inline-block;
  align-self: center;
  text-align: center;
  vertical-align: middle;
  background-color: var(--light-gray);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 1.25rem;
  width: 90%;
  height: 42px;
  // margin-bottom: 46px;
  // margin: 2rem 0 3rem 0;
}

.text_primary {
  color: var(--orange);
  font-size: 0.85rem;
  text-decoration: none;
}

.btn_disabled {
  color: #fff;
  background-color: var(--light-gray);
}

.btn_primary {
  color: #fff;
  background-color: var(--orange);
}

.opacity {
  opacity: 0;
}

.input-captcha {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 3rem;

  .input-box {
    width: 100%;
    max-width: 500px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;

    input {
      vertical-align: middle;
    }

    & + .input-box {
      margin-top: 20px;
    }

    // 六个input时的样式
    .input-content {
      padding: 20px;
      width: 100vw;
      display: flex;
      justify-content: center;
      position: relative;

      .box__img {
        width: 100%;
        position: absolute;
        height: 100%;
        max-width: 18rem;

        img {
          width: 100%;
          height: 48px;
        }
      }

      input {
        padding-left: 1.4rem;
        letter-spacing: 2.7rem;
        max-width: 18rem;
        width: 100%;
        height: 3rem;
        position: relative;
        z-index: 100;
        background-color: transparent;
        // margin-right: -1px;
        border: transparent;
        outline: transparent;
      }
    }

    /* 去掉input[type=number]浏览器默认的icon显示 */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      // chrome
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    input {
      // 火狐
      -moz-appearance: textfield;
    }
  }
}

.position_absolute {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.bindMobilePopup {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;

  .zone {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    height: 120px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;

    .title {
      color: gray;
      text-align: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .box__check {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 16px;
      padding-bottom: 16px;

      div:nth-child(1) {
        width: 50%;
      }

      div:nth-child(2) {
        width: 50%;
        color: rgb(255, 115, 0);
      }
    }
  }
}

@media (orientation: portrait), (min-height: 200px) and (max-height: 480px) {
  .card.card_body {
    height: 40vh;
  }
}

@media (orientation: portrait), (min-height: 480px) {
  .card.card_body {
    height: 40vh;
  }
}

@media (orientation: portrait), (min-height: 400px) {
  .card.card_body {
    height: 40vh;
  }
}
</style>
