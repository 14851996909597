<template>
  <div class="container" :class="{ hidden: !isLoading }">
    <div class="loading">
      <div class="spinner"></div>
      <p>{{ loadingContent }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    loadingContent: {
      type: String,
      default: "正在加载",
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #848484;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 10px;
  margin: 0 auto;
  opacity: 0.85;
  transform: scale(0.85);
}

.spinner {
  position: relative;
  background-color: #848484;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: conic-gradient(#fff, transparent);
  animation: rotate 1s linear infinite;
}

.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.spinner:before {
  width: 50px;
  height: 50px;
  top: 5px;
  left: 5px;
  background-color: #848484;
}

.spinner:after {
  height: 5px;
  width: 5px;
  top: 0;
  left: 45px;
}

p {
  font-weight: bold;
  color: white;
  text-align: center;
  margin-top: 0.65rem;
  font-size: 0.9rem;
}

@keyframes rotate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.25s linear;
}
</style>
