<template>
  <div
    class="backdrop"
    :class="{ closeModal: closeModal }"
    @click="$emit('click', $event.target)"
  >
    <div class="modal">
      <div class="modal_header"></div>
      <div class="modal_body">
        <p class="modal_content">QQ : {{ phone }}</p>
        <div slot="footer" class="btn_group">
          <a class="btn_default btn_primary" target="_blank" :href="url">
            去QQ加好友
          </a>
          <button class="btn_default btn_secondary" @click="$emit('close')">
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QQModal",
  props: {
    closeModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: "",
      phone: 1,
    };
  },
  methods: {
    qqUrl() {
      let webUrl = `http://wpa.qq.com/msgrd?v=3&uin=${this.phone}&site=oicqzone.com&menu=yes`;
      let appUrl = `mqqwpa://im/chat?chat_type=wpa&uin=${this.phone}&version=1&src_type=web&web_src=oicqzone.com`;

      if (
        /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ||
        /(Android)/i.test(navigator.userAgent)
      ) {
        this.url = appUrl;
      } else {
        this.url = webUrl;
      }
    },
  },
  mounted() {
    this.qqUrl();
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  background-color: #1a1a1a80;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 280px;
  width: 75vw;
  max-height: 450px;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-width: 350px;
  .modal_header {
    display: block;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/public/ic_customer_service.webp");
  }
  .modal_body {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/public/ic_customer_service.webp");
    transform: translateY(-0.5rem);
    padding: 0 1.5rem;
  }
  &_content {
    margin-bottom: 2rem;
    line-height: 1.5rem;
    font-size: 0.85rem;
    text-align: left;
    width: 100%;
    transform: translateY(-3rem);
  }
}

@media (min-width: 768px) {
  .modal {
    max-height: 60%;
    .modal_body {
      margin-top: -3rem;
    }
  }
}

.backdrop.closeModal {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s ease-in-out;
}

.btn {
  &_group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
  }
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    margin: 0 0.75rem;
    width: 100%;
    margin-bottom: 1rem;
    text-decoration: none;
  }
  &_primary {
    color: white;
    background-color: var(--orange);
  }
  &_secondary {
    border: 1px solid var(--light-gray);
    color: var(--gray);
    background-color: white;
  }
}
</style>
