<template>
  <div ref="wrapper" :class="{ fullscreen: fullscreen }" @fullscreenchange="onFullscreenChange">
    <!-- 扫描按钮 -->
    <template v-if="!fullscreen">
      <!-- <div class="toggleScan">扫描</div> -->
    </template>

    <!-- 照相机 -->
    <template v-else>
      <qrcode-stream v-if="!destroyed" :key="_uid" :track="selected.value" :camera="camera" @decode="onDecode" @init="onInit">
        <div class="tw-h-full tw-w-full tw-relative">
          <div v-if="!loading" @click="onCloseCamera" class="tw-z-[99999]  tw-absolute tw-top-4 tw-right-4 tw-w-7">
            <img class="tw-w-full" src="../assets/images/VueQrcodeReader/circle-xmark-solid.svg" />
          </div>
          <div v-if="loading" class="loading-indicator">
            Loading...
          </div>
          <div v-show="showScanConfirmation" class="scan-confirmation">
            <img src="../assets/images/VueQrcodeReader/checkmark.svg" alt="Checkmark" width="180px" />
          </div>
        </div>
      </qrcode-stream>
    </template>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  components: {
    QrcodeStream,
  },
  data() {
    const options = [
      { text: "nothing (default)", value: undefined },
      { text: "outline", value: this.paintOutline },
      { text: "centered text", value: this.paintCenterText },
      { text: "bounding box", value: this.paintBoundingBox },
    ];
    const selected = options[3];
    return {
      selected,
      options,
      loading: false,
      destroyed: false,
      camera: "off",
      result: null,
      showScanConfirmation: false,
      fullscreen: false,
    };
  },
  computed: {},
  watch: {
    fullscreen(enterFullscreen) {
      if (enterFullscreen) {
        this.requestFullscreen();
      } else {
        this.exitFullscreen();
      }
    },
    result: {
      immediate: true,
      handler(newValue) {
        if (newValue !== null) {
          setTimeout(() => {
            this.fullscreen = false;
            this.showScanConfirmation = false;
          }, 1000);
        }
      },
    },
  },
  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;
        ctx.strokeStyle = "red";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode;
        const centerX = boundingBox.x + boundingBox.width / 2;
        const centerY = boundingBox.y + boundingBox.height / 2;
        const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width);
        ctx.font = `bold ${fontSize}px sans-serif`;
        ctx.textAlign = "center";
        ctx.lineWidth = 3;
        ctx.strokeStyle = "#35495e";
        ctx.strokeText(detectedCode.rawValue, centerX, centerY);
        ctx.fillStyle = "#5cb984";
        ctx.fillText(rawValue, centerX, centerY);
      }
    },
    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        console.log(error);
        this.destroy();
      } finally {
        this.loading = false;
        this.showScanConfirmation = this.camera === "off";
      }
    },
    async onDecode(content) {
      this.result = content;
      this.$emit("postQRCode", this.result);
      this.pause();
      // await this.timeout(500)
      // this.unpause()
    },
    unpause() {
      this.camera = "auto";
    },
    pause() {
      this.camera = "off";
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    async reload() {
      this.destroyed = true;
      await this.$nextTick();
      this.destroyed = false;
      await this.timeout(500);
      this.unpause();
    },
    async destroy() {
      this.destroyed = true;
      await this.$nextTick();
      this.destroyed = false;
      await this.timeout(500);
      this.pause();
    },
    onFullscreenChange() {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.
      this.fullscreen = document.fullscreenElement !== null;
    },
    requestFullscreen() {
      const elem = this.$refs.wrapper;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      }
      this.destroy();
    },
    toggleScan() {
      this.result = null;
      this.fullscreen = true;
      this.unpause();
    },
    onCloseCamera() {
      this.result = null;
      this.fullscreen = false;
      this.pause();
    },
  },
};
</script>

<style scoped>
.toggleScan {
  display: flex;
  width: 60px;
  height: 60px;
  /* width: 100%;
  height: 100%; */
  align-items: center;
  justify-content: center;
  /* position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  background-color: rgb(255, 6, 6);
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

input[type="file"] {
  outline: none;
  /* margin-top: 20px; */
  display: none;
}

.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.fullscreen-button {
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
}

.fullscreen-button img {
  width: 2rem;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.loading-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
</style>
