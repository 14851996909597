<template>
  <div class="container">
    <Container :padding="false">
      <div class="w_100" @click.self="closeModal">
        <Header theme="light" title="安全密码" class="noClick"></Header>
      </div>
      <ResetPaymentPassword />
    </Container>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Container from "../components/Container.vue";
import ResetPaymentPassword from "../securityCenter/resetPaymentPassword";

export default {
  name: "resetPaymentPassword",
  components: {
    Header,
    Container,
    ResetPaymentPassword,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  z-index: 300;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.w_100 {
  width: 100%;
  position: fixed;
  z-index: 400;
}

.formData {
  padding: 0 1.2rem;
}

.noClick {
  pointer-events: none;
}
</style>
