<template>
  <div class="item" :class="{ border_bottom: borderBottom }" @click="popup">
    <div class="d_flex justify_content_between">
      <div class="d_flex">
        <div class="icon" style="margin-right: 0.5rem" v-if="imageUrl">
          <img :src="imageUrl" />
        </div>
        <div class="d_flex">
          <div class="title">{{ title }}</div>
          <slot name="subTitle" class="sub_title" />
        </div>
      </div>
      <div class="icon">
        <img src="../../../assets/images/public/ic_mine_return.webp" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    imageUrl: {
      type: String,
      default: "",
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "标题",
    },
    openBlankPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popup() {
      if (this.path === "QQservice") {
        this.$emit("open");
      } else {
        this.openBlankPage
          ? window.open(this.path, "_blank")
          : this.$router.push({ path: this.path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.item {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0rem;
  box-sizing: border-box;
}

.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.sub_title {
  padding-left: 0.25rem;
  color: #ce0b0b;
  font-size: 0.85rem;
  font-weight: bold;
}
</style>
